import {Controller} from 'stimulus'
import throttle from 'lodash/throttle'
import Swiper, {Pagination, EffectFade} from 'swiper'

Swiper.use([Pagination, EffectFade])

export default class extends Controller {
  static targets = ['bg']

  connect() {
    this.handleResize = this.handleResize.bind(this);
    window.addEventListener('resize', throttle(this.handleResize, 250))
    this.initSlider();
    document.addEventListener('DOMContentLoaded', this.lazyLoadBG.bind(this))
  }

  disconnect() {
    window.removeEventListener('resize', throttle(this.handleResize, 250))
    if (this.swiper) {
      this.swiper.destroy(true, true)
    }
  }

  /**
   * Initialize the slider -
   * This will initialize the slider based on the window width,
   * and will destroy the slider if it already exists.
   * This is necessary because the slider needs to be re-initialized in order to change the effect,
   * which is different on mobile and tablet.
   * We can't just use the `breakpoints` array in the Swiper config,
   * since at this time, Swiper doesn't support breakpoints configuration for effects.
   * @returns {void}
   */
  initSlider() {

    // get the window width
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    // if the window width is less than 768px, initialize the fade slider
    if (windowWidth < 700) {
      this.initGallerySliderMobile()
    } else {
      // if the window width is less than 1024px, initialize the slide slider
      this.initGallerySliderTablet()
    }
  }

  /**
   * Handle the window resize event -
   * This will destroy the slider if it exists, and then re-initialize it.
   * @returns {void}
   */
  handleResize() {
    // if the slider exists, destroy it
    if (this.swiper) {
      this.swiper.destroy(true, true)
    }
    // re-initialize the slider
    this.initSlider()
  }

  /**
   * Initialize the mobile slider -
   * This will initialize the slider with the fade effect.
   * @returns {void}
   */
  initGallerySliderMobile() {
    this.swiper = new Swiper('.js-featuredStoriesSlider', {
      slidesPerView: 1,
      centeredSlides: true,
      speed: 800,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
  }

  /**
   * Initialize the tablet slider -
   * This will initialize the slider with the slide effect.
   * @returns {void}
   */
  initGallerySliderTablet() {
    this.swiper = new Swiper('.js-featuredStoriesSlider', {
      slidesPerView: 2,
      centeredSlides: false,
      speed: 800,
      spaceBetween: 30,
      autoHeight: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
        1280: {
          enabled: false
        },
      },
    })
  }

  lazyLoadBG() {
    if ('IntersectionObserver' in window) {
      let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible')
            lazyBackgroundObserver.unobserve(entry.target)
          }
        });
      });
      lazyBackgroundObserver.observe(this.bgTarget)
    }
  }
}
